import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const { custom_border_grey } = variables;

export const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: normal;
  position: relative;
  width: 100%;
`;

export const ProductContent = styled.div`
  flex-wrap: wrap;
  display: block;
  padding: 15px;
  padding-bottom: 0;
  white-space: normal;
  position: relative;
`;

export const ItemContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  transition: transform 0.4s 0.1s ease;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 9px 0px rgba(10, 32, 83, 0.21);
  &:hover {
    transform: translateY(-4px);
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const ProductImage = styled.img`
  margin: 0 auto;
  display: block;
  max-height: 160px;
  &.full {
    object-fit: cover;
    object-position: center;
    width: 100%;
    padding: 0;
  }
  ${(props) =>
    props.noImage
      ? `
    padding: 20px;
  `
      : ''}
`;

export const ProductImageWrapper = styled.div`
  margin: 0 auto;
  display: block;
  height: 160px;
  min-width: 100%;
  ${(props) =>
    props.noImage
      ? `
    padding: 20px;
  `
      : ''}
`;

export const LoadingLine = styled.div`
  width: 100%;
  background: #ebebeb;
  height: 12px;
  margin-top: 15px;
`;
