import React from 'react';
import { Grid, Col, Row } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';
import { isNumber } from 'lodash';
import { openModal } from 'state/modals/actions';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import {
  GlobalWarehouseHeroCard,
  GWHeader,
  GWCount,
  GWCountingDiv,
} from './GWHero.styles';

const { color_white: colorWhite, custom_blue: customBlue } = variables;

function GWHero({ counts }) {
  const { asset_count: assetCount } = counts || {};

  return (
    <GlobalWarehouseHeroCard>
      <Grid id="hero-card-grid">
        <Col md={6} lg={8} xl={8} id="global-warehouse-header-details">
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <GWHeader>Buy OCTG From Our Global Warehouse</GWHeader>
            <GWCountingDiv>
              <GWCount>
                {isNumber(assetCount)
                  ? assetCount?.toLocaleString('en-us')
                  : '--'}
              </GWCount>{' '}
              Items in our global warehouse
              <Tooltip
                type="light"
                className="tooltipStyling"
                placement="end"
                refKey="ref"
                size="medium"
                zIndex={5}
                trigger={
                  <Icon
                    color={customBlue}
                    fontAwesome
                    icon="info-circle"
                    fontSize="12px"
                    style={{
                      cursor: 'pointer',
                      color: customBlue,
                      position: 'relative',
                      left: '2px',
                      top: '-10px',
                    }}
                  />
                }
              >
                <div id="tooltipText">
                  {`The PipeSearch Global Warehouse is comprised of exclusive listings,
                    partner inventory, and assets in our network. While we only list a small
                    portion of our material, you can request an item and our team will get 
                    to work to find what you need within our web of trusted partners and
                    suppliers!`}
                </div>
              </Tooltip>
            </GWCountingDiv>
          </div>
        </Col>
        <Col className="actionButtons">
          <Row className="actionButtonRow">
            <Button
              className="requestItemButton"
              onClick={(e) => {
                e.preventDefault();
                openModal({
                  type: 'quickInqueryModalForReal',
                });
              }}
              primary
              pairButton
              size="large"
            >
              Request an Item
              <Icon
                pointer
                color={colorWhite}
                icon="icon-chevron-right"
                style={{ fontSize: '0.8rem', paddingLeft: '10px' }}
              />
            </Button>
            <Button
              className="submitSupplyButton"
              onClick={(e) => {
                e.preventDefault();
                openModal({
                  type: 'quickInquirySupply',
                  data: { isSupply: true },
                });
              }}
              primary
              pairButton
              size="large"
            >
              Submit Supply
              <Icon
                pointer
                color={colorWhite}
                icon="icon-chevron-right"
                style={{ fontSize: '0.8rem', paddingLeft: '10px' }}
              />
            </Button>
          </Row>
        </Col>
      </Grid>
    </GlobalWarehouseHeroCard>
  );
}

GWHero.propTypes = {
  counts: PropTypes.shape({
    asset_count: PropTypes.number,
  }),
};

export default GWHero;
