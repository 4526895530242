import React from 'react';
import history from 'historyContainer';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { MD, XL } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import { ProductsCustomInqure } from './GlobalProductsCustomInquire.styles.js';
const { spacing_lg } = variables;

const GlobalProductsCustomInquire = (props) => {
  function goToCustomItem() {
    trackClick(
      'submitARequest',
      'Request An Item',
      `${props.view}GlobalProductsCustomInquire`
    );
    history.push('/request-for-quote/m/rfq/overview');
  }
  return (
    <ProductsCustomInqure style={{ margin: '0 auto' }}>
      <Grid>
        <Row
          style={{
            padding: '50px 0',
            marginTop: '50px',
            marginBottom: spacing_lg,
          }}
        >
          {!props.addCustomItem || props.addCustomItem === 'complete' ? (
            <Col justifyCenter column alignCenter>
              {props.addCustomItem === 'complete' ? (
                <XL tag="h3" paddingBottomMd bold center white>
                  Your request was received. Click below to request more items
                </XL>
              ) : (
                <XL tag="h3" paddingBottomMd bold white center>
                  {props.title || `Cant Find What You Are Looking For?`}
                </XL>
              )}
              <MD
                tag="h3"
                paddingBottomLg
                white
                style={{ margin: '0 auto', maxWidth: '700px' }}
                center
              >
                The PipeSearch Platform consolidates update-to-date mill
                schedules, lead times, and global market inventory into the
                largest database of corrosion-resistant alloy tubulars in the
                world.
              </MD>
              <Button
                style={{
                  lineHeight: '55px',
                  minWidth: '300px',
                  fontSize: '16px',
                }}
                bold
                size="large"
                onClick={() => goToCustomItem(true)}
                raisedHover="0px 7px 8px rgba(0,0,0,0.25)"
                raised="0px 4px 5px rgba(0,0,0,0.21)"
                primaryOutline
                blueHover
              >
                Request An Item
              </Button>
            </Col>
          ) : null}
        </Row>
      </Grid>
    </ProductsCustomInqure>
  );
};

export default GlobalProductsCustomInquire;
