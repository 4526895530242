import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const { custom_border_grey: customBorderGrey, custom_blue: customBlue } =
  variables;

export const FlowWrapper = styled('div')``;

export const ContentWrapper = styled('div')`
  display: flex;
  button:disabled,
  button:disabled * {
    color: #95a4b4 !important;
    background: #dce3ea !important;
    border-color: #c6d1dc !important;
  }
  .c {
  }
`;

export const LeftContent = styled('div')`
  background-color: #fff;
  height: calc(100vh - 50px);
  overflow-scrolling: touch;
  overflow: auto;
  max-width: 400px;
  flex: 1;
  width: 100%;
  margin-top: 50px;
  padding: 40px 0px 100px 0px;
  border-right: 1px solid ${customBorderGrey};

  @media (max-width: 900px) {
    max-width: 90px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px 100px 10px;
  }
`;

export const ActionButtonWrapper = styled('div')`
  bottom: 100px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 900px) {
    padding: 0;
    margin: 0;
    button {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      display: block;
      margin: 0 auto;
      * {
        display: none;
      }
      &:after {
        content: '+';
      }
    }
  }
`;

export const SidePanelItem = styled('div')`
  background: ${(props) => (props.active ? 'rgba(4, 105, 239, 0.16)' : '#fff')};
  padding: 20px;
  border-bottom: 1px solid ${customBorderGrey};
  .button-group {
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    background: #f7f9fb;
    transition: all 0.2s ease;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 900px) {
    border: 1px solid ${customBorderGrey} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    background: ${(props) => (props.active ? customBlue : '#fff')};
    color: ${(props) => (props.active ? '#fff' : '#000')};
    &:hover {
      color: ${(props) => (props.active ? '#000' : '#fff')};
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`;

export const RightContent = styled('div')`
  height: calc(100vh - 60px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 60px 40px;
  margin-top: 50px;
  max-width: 1000px;
  flex: 1;
  &.right-only {
    margin: 50px auto 0;
  }

  .dropzone-drop.empty {
    min-height: 169px !important;
    margin-bottom: 40px;
  }
  .dropzone-drop:not(.empty) {
    min-height: 239px !important;
    border: 1px solid #ccc;
    margin-bottom: 40px;
  }
  .thumbnail-download-wrapper {
    margin-bottom: 40px;
  }
  @media (max-width: 1300px) {
    padding: 60px 50px;
  }
  @media (max-width: 1000px) {
    padding: 60px 30px;
  }
  @media (max-width: 900px) {
    margin-top: 0px;
    min-height: 0;
  }
  @media (max-width: 500px) {
    padding: 25px 20px;
    padding-bottom: 180px !important;
    .item-selection-accordian-header {
      background-color: #183458;
    }
    &.full-table {
      padding: 0px 20px;
    }
  }
`;

export const HeadlineContentWrapper = styled('div')`
  margin-bottom: 20px;
  padding: 0 20px;
  @media (max-width: 1300px) {
    max-width: 380px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Action = styled.div`
  border-bottom: 1px solid #dbe0e5;
  padding-bottom: 25px;
  margin-bottom: 25px;
  @media (max-width: 900px) {
  }
  @media (max-width: 500px) {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const AuthWrapper = styled('div')`
  .auth-form-wrapper {
    box-shadow: none;
    padding: 0;
    button {
      width: 100%;
    }
    .already {
      color: #7d8c9f;
      font-size: 15px;
    }
  }
`;
