import React, { useEffect, useCallback } from 'react';
import Spinner from 'components/Spinner/Spinner';
import GlobalProductsCustomInquireWrapper from 'components/GlobalProductInquire/GlobalProductsCustomInquireWrapper';
import Flex from 'styled-flex-component';
import Icon from 'components/Icon/Icon';
import { LG, MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { HelmetWrapper } from 'utility/DefaultMeta';
import { get, isEmpty } from 'lodash';
import GetStarted from 'components/PublicPages/GetStarted';
import { variables } from 'theme/variables';
import {
  fetchListings,
  onSearchChange,
  setActiveRfqItem,
  setSearchFilters,
} from 'state/search/actions';
import { getRfqMeta } from 'state/requestForQuote/actions';
import { getCriteriaParams } from 'state/requestForQuote/transformer_criteria';
import { locationShape } from 'propTypesObjects';
import {
  SearchDetailsSpinnerWrapper,
  SearchComponentWrapper,
  SearchWrapper,
  SearchDetailsWrapperInner,
  Filters,
  Content,
  SearchSection,
  SearchSectionInner,
} from './SearchDetails.styles';
import SearchResultsListings from './components/SearchResultsListings/SearchResultsListings';
import SearchFiltersV2 from './components/SearchFilters/SearchFiltersV2';
import SearchPageSearch from './components/SearchPageSearch/SearchPageSearch';

const {
  custom_light_grey: customLightGrey,
  custom_blue: customBlue,
  public_app_max_width: publicAppMaxWidth,
} = variables;

function ListingsSearch({ location }) {
  const { listings, search, rfqItemFilters, rfqMetadata } = useBranch({
    listings: ['search', 'listings'],
    search: ['search', 'searchFilters'],
    alloyList: ['global', 'alloyLists'],
    rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  const keyword = location?.query?.keyword;
  const hasRfqMetadata = !!rfqMetadata;

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !rfqItemFilters) {
      const seed = getCriteriaParams();
      setActiveRfqItem(seed);
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !!rfqItemFilters) {
      const criteria = getCriteriaParams() || {};
      fetchListings({ criteria });
      setSearchFilters({ filters: criteria });
    }
  }, [hasRfqMetadata, !!rfqItemFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const productsAreLoading = get(search, 'loading');
  const noResults = get(listings, 'result.docs.length') === 0;
  const results = get(listings, 'result.docs.length') > 0;

  const loadingOrNoResults = useCallback(() => {
    if (productsAreLoading) {
      return (
        <SearchDetailsSpinnerWrapper>
          <Spinner />{' '}
        </SearchDetailsSpinnerWrapper>
      );
    }
    if (noResults) {
      return (
        <SearchDetailsWrapperInner>
          <Flex style={{ padding: '40px 20px 80px' }} column alignCenter>
            <div>
              <Icon
                style={{ marginRight: '25px' }}
                icon="icon-search-left"
                color="#ccc"
                fontSize="55px"
              />
            </div>
            <LG scaled center bold navy tag="h3" paddingTopLg>
              No Matching Results{' '}
            </LG>
            <MD scaled center navy tag="h4" paddingTopMd>
              Adjust Your Search Criteria To See Matching Results
            </MD>
          </Flex>
        </SearchDetailsWrapperInner>
      );
    }
    return null;
  }, [noResults, productsAreLoading]);

  return (
    <SearchWrapper>
      <div
        style={{
          width: '100%',
          maxWidth: publicAppMaxWidth,
          margin: '0 auto',
          minHeight: '600px',
        }}
      >
        <HelmetWrapper metadata={{}} />

        <SearchComponentWrapper>
          <SearchPageSearch
            onChangeCriteria={onSearchChange}
            activeFilters={rfqItemFilters}
          />
        </SearchComponentWrapper>
        <SearchDetailsWrapperInner
          style={
            (productsAreLoading && !results) || !results
              ? { display: 'none' }
              : {}
          }
        >
          {/* ^^^^^ display none because i dont want to mount and remount every time we dont have data until we have a real search endpoint */}
          <Filters>
            {get(listings, 'result.docs') ? (
              <SearchFiltersV2
                type="search"
                keyword={keyword}
                filters={get(search, 'filters')}
                facets={get(listings, 'result.facets')}
                total={get(listings, 'result.total')}
                sort={get(listings, 'result.sort')}
                pagination={get(listings, 'result.pagination')}
                onFilter={(filters, sorting, changeValue, { reset } = {}) => {
                  onSearchChange(
                    null,
                    changeValue.item.type,
                    { disabled: true, ...changeValue.item },
                    { external: true, filters, changeValue, reset }
                  );
                }}
              />
            ) : null}
          </Filters>
          <Content>
            <SearchSection>
              <SearchSectionInner style={{ marginTop: '-10px' }}>
                <SearchResultsListings listings={listings} />
              </SearchSectionInner>
            </SearchSection>
          </Content>
        </SearchDetailsWrapperInner>
        {loadingOrNoResults()}
      </div>

      {noResults || results ? (
        <div style={{ background: customBlue }}>
          <GlobalProductsCustomInquireWrapper
            title="Need A Specific Item?"
            location={location}
          />
        </div>
      ) : null}

      {noResults || results ? (
        <div style={{ padding: '50px 20px', background: customLightGrey }}>
          <GetStarted />
        </div>
      ) : null}
    </SearchWrapper>
  );
}

ListingsSearch.propTypes = {
  location: locationShape,
};

export default PageViewWrapper(ListingsSearch, { pageName: 'ListingsSearch' });
