import React from 'react';
import numeral from 'numeral';
import { XL, LG, SM } from '@zendeskgarden/react-typography';
import PublicProductsTableWrapper from 'components/PublicProductsTable/PublicProductsTableWrapper';

const SearchResultsProducts = ({ title }) => {
  return (
    <PublicProductsTableWrapper
      fields={{ button: { disabled: false } }}
      tableStyle={{ minHeight: '200px' }}
      disableAdvancedFilters={true}
      disableInitialFetch={true}
      path={['search']}
      renderTitle={(search) => {
        return (
          <React.Fragment>
            <XL style={{ marginBottom: '0' }} bold>
              {title || 'Product Results'}
            </XL>{' '}
            <SM slate paddingHorizontal>
              ({numeral(search).format('0,0')} Results)
            </SM>
          </React.Fragment>
        );
      }}
    />
  );
};

export default SearchResultsProducts;
