import styled from 'styled-components';
import PublicPage from 'components/PublicPages/PublicPage';
import { variables } from 'theme/variables';
import { Col } from '@zendeskgarden/react-grid';
import { PillButtons } from 'theme/Global.styles.js';

const {
  media_lg: mediaLg,
  custom_blue: customBlue,
  color_grey_100: colorGrey100,
  color_grey_300: colorGrey300,
  custom_blue_soft: customBlueSoft,
} = variables;

export const GlobalWarehousePage = styled(PublicPage)`
  padding-top: 0px !important;
`;

export const GWFiltersWrapper = styled(Col)`
  padding: 0 5px;
  max-width: 250px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const GWContentWrapper = styled(Col)`
  padding-top: 20px;
  padding-left: 20px;
  width: 77%;
  max-width: 1900px;
  #globalFilterBar {
    z-index: 8;
    margin-top: -30px;
    width: 100%;
    display: flex;
    flex-wrap: noWrap;
    justify-content: center;
  }
  @media (max-width: 1030px) {
    width: 40%;
  }
`;

export const Filters = styled('div')`
  max-width: 255px;
  min-width: 255px;
  @media ${mediaLg} {
    display: block;
  }
`;

export const GWListingsWrapper = styled('div')`
  margin: 10px 10px 20px 10px;
  max-width: 98%;
  font-size: 22px;
  display: flex;
  flex-wrap: wrap;
  .countingCol {
    display: flex;
    font-size: 1.1rem;
    font-weight: 600;
    justify-content: end;
  }
  #tab-titles {
    height: 50px;
    min-height: 28px;
    width: 100%;
    min-width: 300px;
    flex-wrap: noWrap;
  }
  #tabHeaders {
    display: flex;
    flex-wrap: noWrap;
    justify-content: end;
    align-items: baseline;
  }
  .tabIcons {
    font-size: 1.4rem;
    display: flex;
  }
  @media (max-width: 1125px) {
    #search-header {
      min-width: 400px;
      display: flex !important;
    }
  }
  @media (max-width: 991px) {
    #tab-titles {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 927px) {
    #tab-titles {
      height: 90px;
    }
  }
  @media (max-width: 872px) {
    #tabHeaders {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const GWResultsHeader = styled('h2')`
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  width: max-content;
  @media (max-width: 1020px) {
    font-size: 1.2rem;
  }
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: row;
    font-size: 1.5rem;
    text-align: center;
    white-space: normal;
  }
  @media (max-width: 425px) {
    text-align: left;
    max-width: 350px;
  }
`;

export const GWResultsCount = styled('span')`
  margin-bottom: 0;
  font-weight: 500;
  display: flex;
  min-width: 50px;
  align-items: flex-end;
  color: ${customBlue};
`;

export const ListingsWrapper = styled('div')`
  width: 100%;
  overflow-x: auto;
  background-color: ${colorGrey100};
  padding: 10px;
  border-radius: 0.8em;
  box-shadow: 1px 1px 3px 1px ${colorGrey300} inset,
    -1px -1px 3px 1px ${colorGrey300} inset;
`;

export const CustomPillButtons = styled(PillButtons)`
  height: 38px;
  margin-right: 10px;
  ${(props) => `
    background: ${props.activeColor || customBlueSoft};
  `}
`;
