import React from 'react';
import { PublicItemSelectionFilters } from 'components/ItemSelectionFilters/ItemSelectionFilters';
import { omit } from 'lodash';
import {
  StyledSearchWrapper,
  StyledSearchSearch,
} from './SearchPageSearch.styles.js';

const SearchPageSearch = (props) => {
  const activeFilters = omit(props.activeFilters, ['category', 'quantity']);
  return (
    <StyledSearchWrapper style={props.style || {}}>
      <StyledSearchSearch>
        <PublicItemSelectionFilters
          rowIndex={0}
          onChangeFilters={props.onChangeCriteria}
          filters={activeFilters}
        />
      </StyledSearchSearch>
    </StyledSearchWrapper>
  );
};

export default SearchPageSearch;
