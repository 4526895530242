import React from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { map } from 'lodash';
import ListingGridItem, {
  ListingGridItemUpgrade,
} from 'components/ListingGridItem/ListingGridItem';
import { PropTypes } from 'prop-types';

function SearchResultsListings({ setAuthwall, email, listings }) {
  const allListings = listings?.result?.docs || [];

  return (
    <div>
      <Row>
        {map(allListings, (c, i) => (
          <Col
            key={i}
            size="12"
            sm="3"
            md="3"
            lg="3"
            style={{ marginBottom: 20, minHeight: '100%' }}
          >
            <ListingGridItem
              key={i}
              // LINT OVERRIDE #3
              // Component wraps another component
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...c}
              style={{ marginTop: 0, marginBottom: 0, minHeight: '100%' }}
            />
          </Col>
        ))}
        {!email ? (
          <Col
            onClick={() => setAuthwall(true)}
            key="upgrade"
            size="12"
            sm="3"
            md="3"
            lg="3"
            style={{ marginBottom: 20, minHeight: '100%' }}
          >
            <ListingGridItemUpgrade
              style={{ marginTop: 0, marginBottom: 0, minHeight: '100%' }}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

SearchResultsListings.propTypes = {
  setAuthwall: PropTypes.func,
  email: PropTypes.string,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  listings: PropTypes.array,
};

export default SearchResultsListings;
