import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { getRfqMeta } from 'state/requestForQuote/actions';
import formatSortFields from 'utility/formatSortFields';
import {
  Dropdown,
  Select,
  Item,
  Menu,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { Col, Row } from '@zendeskgarden/react-grid';
import { SM } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import {
  isEmpty,
  omit,
  sortBy,
  map,
  get,
  startCase,
  noop,
  replace,
} from 'lodash';
import {
  prepareCriteria,
  fetchProducts as fetchProductsApi,
  setActiveRfqItem,
  onSearchChange,
} from 'state/search/actions';
import { PublicItemSelectionFilters } from 'components/ItemSelectionFilters/ItemSelectionFilters';
import PublicProductsTable from 'components/PublicProductsTable/PublicProductsTable';
import { PropTypes } from 'prop-types';
import { paginationShape } from 'propTypesObjects';
import { getValues } from 'utility/urlUtils';
import {
  Content,
  ProductsSectionHeader,
  ProductsSection,
  ProductsSectionInner,
} from './PublicProductsTableWrapper.styles';

function PublicProductsTableWrapper({
  showFiltersByDefault,
  path,
  disableInitialFetch,
  disableAdvancedFilters,
  renderTitle,
  title,
  fields,
  tableStyle,
}) {
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState(showFiltersByDefault);

  const { search, rfqItemFilters, rfqMetadata } = useBranch({
    search: [...path, 'c'],
    rfqItemFilters: [...path, 'searchItemFilter', 'rfqItemFilters'],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  const hasRfqMetadata = !!rfqMetadata;

  function fetchProducts(data) {
    const activeCriteria = prepareCriteria();
    const currentData = data || {};
    currentData.criteria = activeCriteria || data?.criteria || {};
    fetchProductsApi(currentData);
  }

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!disableInitialFetch) {
      const querystring = getValues();
      fetchProductsApi({ filters: querystring });
    }
  }, [disableInitialFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !disableAdvancedFilters) {
      setActiveRfqItem();
    }
  }, [hasRfqMetadata, disableAdvancedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const paginationAndFilterPaths = {
    paths: {
      result: [...path, 'c', 'result'],
      pagination: [...path, 'c', 'result'],
      filters: [...path, 'c', 'filters'],
    },
    active: {
      filters: get(search, 'filters', {}),
      pagination: get(search, 'result.pagination', {}),
      sort: get(search, 'result.sort', {}),
    },
  };

  const productsAreLoading = get(search, 'loading');

  return (
    <div>
      <Content>
        <ProductsSection>
          <ProductsSectionInner>
            <div style={{ marginBottom: '40px' }}>
              <Row alignCenter justifyBetween style={{ marginBottom: '20px' }}>
                {renderTitle ? (
                  <Col size="auto" alignCenter>
                    {renderTitle(
                      get(search, 'result.total'),
                      get(search, 'result.pagination', {})
                    )}
                  </Col>
                ) : (
                  <Col size="auto" alignCenter>
                    <ProductsSectionHeader style={{ marginBottom: '0' }} bold>
                      {title || 'Product Results'}
                    </ProductsSectionHeader>{' '}
                    <SM slate paddingHorizontal>
                      ({numeral(get(search, 'result.total') || 0).format('0,0')}{' '}
                      Results)
                    </SM>
                  </Col>
                )}
                <Col justifyEnd alignCenter>
                  <Row>
                    {!disableAdvancedFilters ? (
                      <Col>
                        <SM
                          style={{ whiteSpace: 'nowrap', padding: '12px 0' }}
                          onClick={() =>
                            setShowAdvancedFilters(!showAdvancedFilters)
                          }
                          link
                        >
                          {showAdvancedFilters
                            ? 'Hide Filters'
                            : 'Product Search'}
                        </SM>
                      </Col>
                    ) : null}
                    <Col>
                      <div style={{ minWidth: '260px' }}>
                        <RenderDropdown
                          loading={productsAreLoading}
                          {...{
                            sortFields: get(search, 'result.sort_fields'),
                            pagination: paginationAndFilterPaths,
                            fetchMore: fetchProducts,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showAdvancedFilters ? (
                <Row>
                  <Col size="12">
                    <PublicItemSelectionFilters
                      rowIndex={0}
                      onChangeFilters={onSearchChange}
                      filters={omit(rfqItemFilters, ['category', 'quantity'])}
                      inline
                    />
                  </Col>
                </Row>
              ) : null}
              <PublicProductsTable
                fieldSettings={fields}
                tableStyle={tableStyle}
                loading={productsAreLoading}
                items={get(search, 'result.docs')}
                fetchMore={({ pagination, filters, sorting }) => {
                  fetchProducts({ pagination, criteria: filters, sorting });
                }}
                pagination={paginationAndFilterPaths}
              />
            </div>
          </ProductsSectionInner>
        </ProductsSection>
      </Content>
    </div>
  );
}

PublicProductsTableWrapper.propTypes = {
  showFiltersByDefault: PropTypes.bool,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  path: PropTypes.array,
  disableInitialFetch: PropTypes.bool,
  disableAdvancedFilters: PropTypes.bool,
  renderTitle: PropTypes.func,
  title: PropTypes.string,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array,
  tableStyle: PropTypes.string,
};

function RenderDropdown({ loading, sortFields, pagination, fetchMore }) {
  const [localSort, setLocalSort] = useState();
  const sort =
    get(pagination, 'active.sort.sort') && !loading
      ? get(pagination, 'active.sort')
      : localSort || {};

  function onClickSort({ field }) {
    const data = formatSortFields({ field, sort });
    setLocalSort(data.sorting);
    fetchMore({
      ...data,
      filters: pagination.active.filters,
      pagination: pagination.active.pagination,
    });
  }

  return (
    <Dropdown selectedItem={sort.sort} onSelect={noop}>
      <Field>
        <Select
          style={{ minWidth: '100%', maxWidth: '100%' }}
          background="#fff"
          active={false}
          fontSize="13px"
        >
          {sort.sort ? (
            <SM>
              <SM as="span" bold>
                Sorting:{' '}
              </SM>{' '}
              <SM as="span">{startCase(replace(sort.sort, /_/g, ' '))}</SM>{' '}
              <SM as="span" grey>
                ({sort.direction})
              </SM>
            </SM>
          ) : (
            <SM slate bold>
              Sort Products
            </SM>
          )}
        </Select>
      </Field>
      <Menu maxHeight="200px">
        {map(
          sortBy(
            map(sortFields, (o) => ({ option: o })),
            'option'
          ),
          ({ option }) => (
            <Item
              onClick={() => {
                onClickSort({ field: option });
              }}
              key={option}
              value={option}
            >
              {startCase(replace(option, /_/g, ' '))}
            </Item>
          )
        )}
      </Menu>
    </Dropdown>
  );
}

RenderDropdown.propTypes = {
  loading: PropTypes.bool,
  sortFields: PropTypes.arrayOf(PropTypes.string),
  pagination: paginationShape,
  fetchMore: PropTypes.func,
};

export default PublicProductsTableWrapper;
