import React from 'react';
import { openModal } from 'state/modals/actions';
import { Grid, Col, Row } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { variables } from 'theme/variables';
import {
  ProductGuideHeroCard,
  ProductGuideHeader,
  ProductGuideSubHeader,
} from './ProductGuideHero.style';

const { color_white: colorWhite } = variables;

function ProductGuideHero() {
  return (
    <ProductGuideHeroCard>
      <Grid id="heroCardGrid">
        <Col lg={12} xl={7} id="productGuideHeaderDetails">
          <ProductGuideHeader>OCTG Product Guide</ProductGuideHeader>
          <ProductGuideSubHeader>
            View our Comprehensive Catalog of OCTG products and specifications
            to help you make the right decisions for your project
          </ProductGuideSubHeader>
        </Col>
        <Col lg={12} xl={5} id="actionButtons">
          <Row className="expertButtonRow">
            <Button
              className="expertButton"
              onClick={(e) => {
                e.preventDefault();
                openModal({
                  type: 'contactUsModal',
                });
              }}
              primary
              pairButton
              size="large"
            >
              Speak with an Expert
            </Button>
          </Row>
          <Row className="submitSupplyButtonRow">
            <Button
              className="submitSupplyButton"
              onClick={(e) => {
                e.preventDefault();
                openModal({
                  type: 'quickInquirySupply',
                  data: { isSupply: true },
                });
              }}
              primary
              pairButton
              size="large"
            >
              Submit Supply
              <Icon
                pointer
                color={colorWhite}
                icon="icon-chevron-right"
                style={{ fontSize: '0.8rem', paddingLeft: '10px' }}
              />
            </Button>
          </Row>
        </Col>
      </Grid>
    </ProductGuideHeroCard>
  );
}

export default ProductGuideHero;
