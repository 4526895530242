import React, { useEffect, useState } from 'react';
import { XL, SM } from '@zendeskgarden/react-typography';
import numeral from 'numeral';
import { useBranch } from 'baobab-react/hooks';
import { getContentForSearch } from 'state/search/actions';
import { Row, Col } from '@zendeskgarden/react-grid';
import { map, get, take } from 'lodash';
import { BlogPostItem } from 'routes/BlogList/components/BlogPosts/BlogPosts';

const SearchResultsContent = (props) => {
  const [clamp, setClamp] = useState(true);
  const { content } = useBranch({
    content: ['search', 'content'],
  });

  useEffect(() => {
    getContentForSearch();
  }, []);

  const allContent = get(content, 'result.docs') || [];
  const contentClamp = clamp ? take(allContent, '4') : allContent;

  return (
    <div>
      <Row justifyBetween style={{ paddingBottom: '30px' }}>
        <Col alignCenter>
          <XL style={{ marginBottom: '0' }} bold>
            {'Featured Resources and Articles'}
          </XL>{' '}
          <SM slate paddingHorizontal>
            ({numeral(get(content, 'result.total')).format('0,0')} Results)
          </SM>
        </Col>
        {allContent && allContent.length > 4 ? (
          <Col size="auto" alignCenter justifyEnd>
            <SM
              onClick={() => setClamp(!clamp)}
              style={{ marginBottom: '0' }}
              link
            >
              {clamp ? 'See All Resources' : 'Hide Resources'}
            </SM>
          </Col>
        ) : null}
      </Row>
      <Row>
        {map(contentClamp, (c, i) => {
          return (
            <Col key={i} size="12" sm="3" md="3" lg="3">
              <BlogPostItem small="true" clamp="3" content={c} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SearchResultsContent;
