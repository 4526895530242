import React, { useEffect, useState } from 'react';
import { useBranch } from 'baobab-react/hooks';
import { HelmetWrapper, basicHelmetGenerator } from 'utility/DefaultMeta';
import { get, isEmpty, omit } from 'lodash';
import { locationShape } from 'propTypesObjects';
import { Grid, Col, Row } from '@zendeskgarden/react-grid';
import { getRfqMeta } from 'state/requestForQuote/actions';
import { getCriteriaParams } from 'state/requestForQuote/transformer_criteria';
import { variables } from 'theme/variables';
import Icon from 'components/Icon/Icon';
import { PublicItemSelectionFilters } from 'components/ItemSelectionFilters/ItemSelectionFilters';
import { getLandingCount } from 'state/landing/actions';
import { PillButton } from 'theme/Global.styles';
import GWHero from './components/GWHero';
import SimpleListingsTable from './components/ListingsTable';
import SearchFiltersV2 from '../SearchResults/routes/SearchDetails/components/SearchFilters/SearchFiltersV2';
import {
  GlobalWarehousePage,
  Filters,
  GWFiltersWrapper,
  GWContentWrapper,
  GWListingsWrapper,
  GWResultsHeader,
  ListingsWrapper,
  CustomPillButtons,
} from './GlobalWarehouse.styles';
import {
  onSearchChange,
  fetchListings,
  setActiveRfqItem,
  setSearchFilters,
} from '../../state/search/actions';

const { custom_blue: customBlue, custom_dark_grey: customDarkGrey } = variables;

function GlobalWarehouse({ location }) {
  const [currentTab, setCurrentTab] = useState('list');
  const { listings, search, rfqItemFilters, rfqMetadata, landingCount } =
    useBranch({
      listings: ['search', 'listings'],
      search: ['search', 'searchFilters'],
      alloyList: ['global', 'alloyLists'],
      rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
      rfqMetadata: ['requestForQuote', 'meta', 'result'],
      landingCount: ['landing', 'landing', 'result'],
    });

  const listingDocs = get(listings, 'result.docs');

  const keyword = location?.query?.keyword;
  const hasRfqMetadata = !!rfqMetadata;

  useEffect(() => {
    getLandingCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata) {
      const seed = getCriteriaParams();
      setActiveRfqItem(seed);
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata) {
      const criteria = getCriteriaParams() || {};
      fetchListings({ filters: { ...criteria, status: ['active'] } });
      setSearchFilters({ filters: { ...criteria, status: ['active'] } });
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function resetFilter() {
    onSearchChange(null, null, null, { reset: true });
  }

  return (
    <GlobalWarehousePage>
      <HelmetWrapper
        metadata={basicHelmetGenerator({
          title: 'Pipesearch - Global Warehouse Search',
        })}
        style={{ paddingTop: '0px !important' }}
      />

      <Grid style={{ display: 'flex' }}>
        <GWFiltersWrapper>
          <Filters>
            <SearchFiltersV2
              type="search"
              keyword={keyword}
              filters={get(search, 'filters')}
              facets={get(listings, 'result.facets')}
              total={get(listings, 'result.total')}
              sort={get(listings, 'result.sort')}
              pagination={get(listings, 'result.pagination')}
              onFilter={(filters, _sorting, changeValue, { reset } = {}) => {
                onSearchChange(
                  null,
                  changeValue?.item?.type,
                  { disabled: true, ...changeValue?.item },
                  { external: true, filters, changeValue, reset }
                );
              }}
            />
          </Filters>
        </GWFiltersWrapper>
        <GWContentWrapper>
          <GWHero counts={landingCount} />
          <div id="globalFilterBar">
            <PublicItemSelectionFilters
              style={{
                width: '95%',
                boxShadow: `1px 1px 7px -2px ${customDarkGrey}`,
              }}
              rowIndex={0}
              onChangeFilters={onSearchChange}
              resetFilters={() => resetFilter()}
              filters={omit(rfqItemFilters, ['category', 'quantity'])}
              inline
              search
              tableVisible
              page="globalWarehouse"
            />
          </div>
          <GWListingsWrapper>
            <Row id="tab-titles">
              <Col id="search-header">
                <GWResultsHeader>
                  {keyword
                    ? `Showing Results for ${keyword}`
                    : `OCTG Global Warehouse Exclusive Inventory`}
                </GWResultsHeader>
              </Col>
              <Col id="tabHeaders">
                <CustomPillButtons>
                  <PillButton
                    onClick={() => setCurrentTab('list')}
                    hover
                    active={currentTab === 'list'}
                  >
                    <Icon
                      className="tabIcons"
                      icon="bars"
                      fontAwesome
                      color={currentTab === 'list' ? '#fff' : customBlue}
                      iconPrefix="far"
                    />
                  </PillButton>
                  <PillButton
                    onClick={() => setCurrentTab('feed')}
                    hover
                    active={currentTab === 'feed'}
                  >
                    <Icon
                      className="tabIcons"
                      icon="distribute-spacing-vertical"
                      fontAwesome
                      color={currentTab === 'feed' ? '#fff' : customBlue}
                      iconPrefix="far"
                    />
                  </PillButton>
                </CustomPillButtons>
                <div className="countingCol">
                  <span style={{ color: customBlue, paddingRight: '7px' }}>
                    {`${get(listings, 'result.total')} `}
                  </span>
                  matches
                </div>
              </Col>
            </Row>
            <ListingsWrapper>
              <SimpleListingsTable
                listings={listingDocs}
                tableType={currentTab}
              />
            </ListingsWrapper>
          </GWListingsWrapper>
        </GWContentWrapper>
      </Grid>
    </GlobalWarehousePage>
  );
}

GlobalWarehouse.propTypes = {
  location: locationShape,
};

export default GlobalWarehouse;
