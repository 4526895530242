import styled from 'styled-components/macro';
import { LG } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';

const { spacing } = variables;

export const ProductsSection = styled('div')`
  &&& .filter-wrapper.not_small {
    max-width: 400px;
    @media (max-width: 1050px) {
      min-width: 130px !important;
    }
    @media (max-width: 780px) {
      min-width: 33% !important;
    }
    @media (max-width: 580px) {
      min-width: 50% !important;
    }
  }
`;

export const ProductsSectionHeader = styled(LG)`
  font-size: 26px;
  margin-bottom: ${spacing};
`;

export const Content = styled('div')`
  max-width: 100%;
`;

export const ProductsSectionInner = styled('div')`
  @media (min-width: 600px) {
    .filter-wrapper.od {
      padding-left: 0 !important;
    }
    .filter-wrapper.brand {
      padding-right: 0 !important;
    }
  }
`;
