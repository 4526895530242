import React from 'react';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import { openModal } from 'state/modals/actions';
import { variables } from 'theme/variables';
import { isNumber } from 'lodash';
import { PropTypes } from 'prop-types';
import { BottomBannerCard, BottomBannerHeader } from './BottomBanner.style';
import Icon from '../../../components/Icon/Icon';

const { color_white: colorWhite, custom_blue: customBlue } = variables;

function BottomBanner({ counts }) {
  const { asset_count: assetCount } = counts || {};

  return (
    <BottomBannerCard>
      <Grid id="bottom-card-grid">
        <BottomBannerHeader>
          <div className="inventoryHeader">Need a Specific Item?</div>
          <div className="countHeader">
            {' '}
            <span className="count" style={{ color: customBlue }}>
              {isNumber(assetCount)
                ? assetCount?.toLocaleString('en-us')
                : '--'}
            </span>
            Items in our Global Network
          </div>
        </BottomBannerHeader>
        <Row>
          <Button
            className="requestItemButton"
            onClick={(e) => {
              e.preventDefault();
              openModal({
                type: 'quickInqueryModalForReal',
              });
            }}
            primary
            pairButton
            size="large"
            style={{ width: '300px' }}
          >
            Request an Item
            <Icon
              pointer
              color={colorWhite}
              icon="icon-chevron-right"
              style={{ fontSize: '0.8rem', paddingLeft: '10px' }}
            />
          </Button>
        </Row>
      </Grid>
    </BottomBannerCard>
  );
}

BottomBanner.propTypes = {
  counts: PropTypes.shape({
    asset_count: PropTypes.number,
  }),
};

export default BottomBanner;
