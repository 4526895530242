import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import * as effects from './effects';

const landingCursor = tree.select(['landing']);

export async function getLandingCount() {
  await asyncTreeRequester({
    func: effects.getLandingCount.bind(this),
    cursor: landingCursor,
    path: ['landing'],
  });
}

export default getLandingCount;
