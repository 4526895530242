import React, { useEffect } from 'react';
import { useBranch } from 'baobab-react/hooks';
import { HelmetWrapper, basicHelmetGenerator } from 'utility/DefaultMeta';
import { get, isEmpty } from 'lodash';
import SearchFiltersV2 from 'routes/SearchResults/routes/SearchDetails/components/SearchFilters/SearchFiltersV2';
import { Grid } from '@zendeskgarden/react-grid';
import { getRfqMeta } from 'state/requestForQuote/actions';
import { getCriteriaParams } from 'state/requestForQuote/transformer_criteria';
import { locationShape } from 'propTypesObjects';
import { getLandingCount } from 'state/landing/actions';
import LandingProducts from './components/LandingProducts/LandingProducts';
import {
  ProductsPageWrapper,
  ProductsPageFiltersWrapper,
  Filters,
  ProductPageContentWrapper,
} from './ProductsPage.style';
import ProductGuideHero from './components/ProductGuideHero';
import {
  onSearchChange,
  setActiveRfqItem,
  setSearchFilters,
} from '../../state/search/actions';
import BottomBanner from './components/BottomBanner';

function ProductsPage({ location }) {
  const { products, search, rfqItemFilters, rfqMetadata, landingCount } =
    useBranch({
      products: ['search', 'c'],
      search: ['search', 'searchFilters'],
      rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
      rfqMetadata: ['requestForQuote', 'meta', 'result'],
      landingCount: ['landing', 'landing', 'result'],
    });

  const keyword = location?.query?.keyword;
  const hasRfqMetadata = !!rfqMetadata;

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLandingCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata) {
      const seed = getCriteriaParams();
      setActiveRfqItem(seed);
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !!rfqItemFilters) {
      const criteria = getCriteriaParams() || {};
      setSearchFilters({ filters: criteria });
    }
  }, [hasRfqMetadata, !!rfqItemFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProductsPageWrapper>
      <HelmetWrapper
        metadata={basicHelmetGenerator({
          title: 'Pipesearch - Product Guide',
        })}
      />
      <Grid style={{ display: 'flex' }}>
        <ProductsPageFiltersWrapper>
          <Filters>
            <SearchFiltersV2
              type="search"
              keyword={keyword}
              filters={get(search, 'filters')}
              facets={get(products, 'result.facets')}
              total={get(products, 'result.total')}
              sort={get(products, 'result.sort')}
              pagination={get(products, 'result.pagination')}
              onFilter={(filters, sorting, changeValue, { reset } = {}) => {
                onSearchChange(
                  null,
                  changeValue?.item?.type,
                  { disabled: true, ...changeValue?.item },
                  { external: true, filters, changeValue, reset }
                );
              }}
            />
          </Filters>
        </ProductsPageFiltersWrapper>
        <ProductPageContentWrapper>
          <ProductGuideHero />
          <LandingProducts />
        </ProductPageContentWrapper>
      </Grid>
      <BottomBanner counts={landingCount} />
    </ProductsPageWrapper>
  );
}

ProductsPage.propTypes = {
  location: locationShape,
};

export default ProductsPage;
