import styled from 'styled-components';
import { variables } from 'theme/variables';
import { Row } from '@zendeskgarden/react-grid';

const { custom_dark_grey: customDarkGrey, color_grey_600: colorGrey600 } =
  variables;

export const BottomBannerCard = styled('div')`
  height: 250px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 1em;
  box-shadow: 1px 1px 7px -2px ${customDarkGrey};
  background-image: url(/images/offshoreOilRig.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30%;
  #bottom-card-grid {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 290px;
    justify-content: center;
    align-items: center;
  }
  .requestItemButton {
    max-height: 50px;
    min-width: 200px;
    margin-right: 20px;
  }
  @media (max-width: 1400px) {
    #bottom-card-grid {
      width: 98%;
    }
  }
  @media (max-width: 1240px) {
    .requestItemButton {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 768px) {
    background-image: none;
  }
  @media (max-width: 425px) {
    #bottom-card-grid {
      flex-wrap: wrap;
    }
    .requestItemButton {
      min-width: 300px;
    }
  }
`;

export const BottomBannerHeader = styled(Row)`
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 50px;
  .inventoryHeader {
    font-size: 3.2rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .count {
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    padding-right: 10px;
  }
  .countHeader {
    font-size: 16px;
    color: ${colorGrey600};
    display: flex;
    align-items: center;
  }
  @media (max-width: 1024px) {
    .inventoryHeader {
      font-size: 2.5rem;
    }
    .count {
      font-size: 2rem;
    }
  }
  @media (max-width: 820px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .inventoryHeader {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 410px) {
    .inventoryHeader {
      font-size: 2rem;
    }
    .count {
      font-size: 1.5rem;
    }
  }
`;
