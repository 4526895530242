import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from 'historyContainer';
import Spinner from 'components/Spinner/Spinner';
import GlobalProductsCustomInquireWrapper from 'components/GlobalProductInquire/GlobalProductsCustomInquireWrapper';
import Flex from 'styled-flex-component';
import Icon from 'components/Icon/Icon';
import { LG, MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { HelmetWrapper } from 'utility/DefaultMeta';
import { get, isEmpty } from 'lodash';
import SearchPageSearch from './components/SearchPageSearch/SearchPageSearch.js';

import GetStarted from 'components/PublicPages/GetStarted';

import SearchFiltersV2 from './components/SearchFilters/SearchFiltersV2';
import SearchResultsProducts from './components/SearchResultsProducts/SearchResultsProducts';

import SearchResultsContent from './components/SearchResultsContent/SearchResultsContent';
import SearchResultsListings from './components/SearchResultsListings/SearchResultsListings';

import {
  SearchDetailsSpinnerWrapper,
  SearchComponentWrapper,
  SearchWrapper,
  SearchDetailsWrapperInner,
  Filters,
  Content,
  SearchSection,
  SearchSectionInner,
} from './SearchDetails.styles';
import { variables } from 'theme/variables';
import { getValues } from 'utility/urlUtils';

import {
  setSearchFilters,
  fetchProducts,
  fetchListings,
  onSearchChange,
  setActiveRfqItem,
} from 'state/search/actions';
import { getRfqMeta } from 'state/requestForQuote/actions';
import { getCriteriaParams } from 'state/requestForQuote/transformer_criteria';
import AccountModal from 'components/AccountModal/AccountModal';

import { XL, SM } from '@zendeskgarden/react-typography';
import numeral from 'numeral';
import { Row, Col } from '@zendeskgarden/react-grid';

const { custom_light_grey, custom_blue, public_app_max_width } = variables;

const SearchDetails = (props) => {
  const [authWall, setAuthwall] = useState(false);
  const { email, searchPath, listings, search, rfqItemFilters, rfqMetadata } =
    useBranch({
      listings: ['search', 'listings'],
      search: ['search', 'searchFilters'],
      searchPath: ['search', 'c'],
      alloyList: ['global', 'alloyLists'],
      rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
      rfqMetadata: ['requestForQuote', 'meta', 'result'],
      email: ['authentication', 'session', 'email'],
    });

  const keyword = get(props, 'location.query.keyword');
  const hasRfqMetadata = !!rfqMetadata;

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !rfqItemFilters) {
      const seed = getCriteriaParams();
      setActiveRfqItem(seed);
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !!rfqItemFilters) {
      const values = getValues();
      const hasCurrentFilters = !isEmpty(values);
      let criteria = getCriteriaParams() || {};
      fetchListings(
        !hasCurrentFilters
          ? {
              pagination: { limit: 100 },
              filters: {},
              criteria,
              sorting: { direction: 'desc', sort: 'create_timestamp' },
            }
          : { criteria }
      );
      fetchProducts({ criteria });
      setSearchFilters({ filters: { ...criteria, ...(values || {}) } });
    }
  }, [hasRfqMetadata, !!rfqItemFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const productsAreLoading = get(search, 'loading');
  const noResults = get(listings, 'result.docs.length') === 0;
  const results = get(listings, 'result.docs.length') > 0;

  function onAuthentication() {
    history.push('/dashboard/listings');
  }

  function onChangeCriteria(branch, type, data) {
    onSearchChange(branch, type, data);
  }

  return (
    <SearchWrapper>
      <div
        style={{
          width: '100%',
          maxWidth: public_app_max_width,
          margin: '0 auto',
          minHeight: '600px',
        }}
      >
        <HelmetWrapper metadata={{}} />

        <SearchComponentWrapper>
          <SearchPageSearch
            onChangeCriteria={onChangeCriteria.bind(this)}
            activeFilters={rfqItemFilters}
            extraFilters={get(search, 'filters')}
          />
        </SearchComponentWrapper>
        <SearchDetailsWrapperInner
          style={
            (productsAreLoading && !results) || !results
              ? { display: 'none' }
              : {}
          }
        >
          {/* ^^^^^ display none because so we dont mount and remount every time we dont hve data until we have a real search endpoint*/}
          <Filters>
            {results || get(searchPath, 'result.docs') ? (
              <SearchFiltersV2
                type="listing"
                keyword={keyword}
                filters={get(search, 'filters')}
                facets={get(listings, 'result.facets')}
                total={get(listings, 'result.total')}
                sort={get(listings, 'result.sort')}
                pagination={get(listings, 'result.pagination')}
                onFilter={(filters, sorting, changeValue, { reset } = {}) => {
                  onSearchChange(
                    null,
                    changeValue.item.type,
                    { disabled: true, ...changeValue.item },
                    { external: true, filters, changeValue, reset }
                  );
                }}
              />
            ) : null}
          </Filters>
          <Content>
            <SearchSection>
              <SearchSectionInner style={{ marginTop: '-10px' }}>
                <Row justifyBetween style={{ paddingBottom: '20px' }}>
                  <Col alignCenter>
                    <XL style={{ marginBottom: '0' }} bold>
                      {'Featured Listings'}
                    </XL>{' '}
                    <SM slate paddingHorizontal>
                      ({numeral(get(listings, 'result.total')).format('0,0')}{' '}
                      Results)
                    </SM>
                  </Col>
                  <Col size="auto" alignCenter justifyEnd>
                    {email ? (
                      <Link to="/dashboard/listings">
                        <SM blue style={{ marginBottom: '0' }} link bold>
                          See All Listings
                        </SM>
                      </Link>
                    ) : (
                      <SM
                        onClick={() => setAuthwall(true)}
                        blue
                        style={{ marginBottom: '0' }}
                        link
                        bold
                      >
                        See All Listings
                      </SM>
                    )}
                  </Col>
                </Row>
                <LG
                  style={{ maxWidth: '800px' }}
                  paddingBottomLg
                  navy
                  light
                  tag="h3"
                >
                  Currently displaying{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {get(listings, 'result.total') || 0}
                  </span>{' '}
                  of our <span style={{ fontWeight: 'bold' }}>7,653</span>{' '}
                  global listings available on PipeSeach.{' '}
                  {!email ? (
                    <span>
                      To view more{' '}
                      <LG as="span" link blue onClick={() => setAuthwall(true)}>
                        create an account
                      </LG>
                      .
                    </span>
                  ) : null}
                </LG>
                <SearchResultsListings
                  email={email}
                  setAuthwall={setAuthwall}
                  listings={listings}
                />
              </SearchSectionInner>
            </SearchSection>

            <SearchSection>
              <SearchSectionInner>
                <SearchResultsProducts />
              </SearchSectionInner>
            </SearchSection>

            <SearchSection>
              <SearchSectionInner>
                <SearchResultsContent />
              </SearchSectionInner>
            </SearchSection>
          </Content>
        </SearchDetailsWrapperInner>

        {productsAreLoading ? (
          <SearchDetailsSpinnerWrapper>
            <Spinner />{' '}
          </SearchDetailsSpinnerWrapper>
        ) : noResults ? (
          <SearchDetailsWrapperInner>
            <Flex style={{ padding: '40px 20px 80px' }} column alignCenter>
              <div>
                <Icon
                  style={{ marginRight: '25px' }}
                  icon="icon-search-left"
                  color={'#ccc'}
                  fontSize={'55px'}
                />
              </div>
              <LG scaled center bold navy tag="h3" paddingTopLg>
                No Matching Results{' '}
              </LG>
              <MD scaled center navy tag="h4" paddingTopMd>
                Adjust Your Search Criteria To See Matching Results
              </MD>
            </Flex>
          </SearchDetailsWrapperInner>
        ) : null}
      </div>

      {noResults || results ? (
        <div style={{ background: custom_blue }}>
          <GlobalProductsCustomInquireWrapper
            title={'Need A Specific Item?'}
            location={props.location}
          />
        </div>
      ) : null}

      {noResults || results ? (
        <div style={{ padding: '50px 20px', background: custom_light_grey }}>
          <GetStarted />
        </div>
      ) : null}

      <AccountModal
        cta="See All Listings"
        title="Sign up to see all listings"
        onAuthentication={onAuthentication}
        onClose={() => setAuthwall(false)}
        isDefaultOpen={authWall}
        {...props}
      />
    </SearchWrapper>
  );
};

export default PageViewWrapper(SearchDetails, { pageName: 'SearchDetails' });
