import styled from 'styled-components';
import { variables } from 'theme/variables';

const {
  custom_blue: customBlue,
  color_blue_700: colorBlue700,
  custom_slate: customSlate,
} = variables;

export const GlobalWarehouseHeroCard = styled('div')`
  height: 280px;
  max-width: 100%;
  margin: 2px 10px;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 4px 16px #c0cff7;
  background-image: url('/images/GlobalBinaryMap.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 60% 100%;
  background-clip: border-box;
  .requestItemButton {
    min-height: 50px;
    max-height: 50px;
    min-width: 300px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .submitSupplyButton {
    min-height: 50px;
    max-height: 50px;
    min-width: 300px;
    background-color: ${colorBlue700} !important;
  }
  #hero-card-grid {
    display: flex;
    justify-content: center;
  }
  #global-warehouse-header-details {
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .actionButtons {
    display: flex;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
    max-width: 35%;
  }
  @media (max-width: 1633px) {
    .requestItemButton {
      margin-bottom: 20px;
    }
    .submitSupplyButton {
      margin-right: 20px;
    }
  }
  @media (max-width: 1310px) {
    .requestItemButton {
      margin-right: 0;
    }
    .submitSupplyButton {
      margin-right: 0;
    }
  }
  @media (max-width: 1240px) {
    .requestItemButton {
      min-width: 190px;
    }
    .submitSupplyButton {
      min-width: 190px;
    }
  }
  @media (max-width: 850px) {
    .requestItemButton {
      min-width: 200px;
      margin-right: 0;
    }
    .submitSupplyButton {
      min-width: 200px;
    }
    #hero-card-grid {
      flex-wrap: wrap;
      height: 200px;
    }
  }
  @media (max-width: 810px) {
    .requestItemButton {
      min-width: 179px;
    }
    .submitSupplyButton {
      min-width: 179px;
    }
  }
  @media (max-width: 767px) {
    background-image: none;
    .requestItemButton {
      margin-right: 10px;
    }
    #global-warehouse-header-details {
      height: 165px;
    }
    .actionButtonRow {
      flex-wrap: noWrap;
    }
  }
  @media (max-width: 475px) {
    .requestItemButton {
      min-width: 180px;
    }
    .submitSupplyButton {
      min-width: 180px;
    }
    .actionButtonRow {
      flex-wrap: wrap;
      margin-top: -10px;
    }
    #global-warehouse-header-details {
      height: 120px;
      padding-left: 0;
    }
    #hero-card-grid {
      padding: 0;
    }
  }
`;

export const GWHeader = styled('h1')`
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  width: 100%;
  justify-content: start;
  margin-bottom: 25px;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }
  @media (max-width: 475px) {
    margin-bottom: 15px;
  }
  @media (max-width: 403px) {
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;
  }
`;

export const GWCountingDiv = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 500;
  color: ${customSlate};
  line-height: 22px !important;
  .tooltipStyling {
    max-width: 300px;
    border-radius: 20px;
    color: ${customSlate};
    box-shadow: 0 4px 16px #c0cff7;
    border: none;
  }
  @media (max-width: 475px) {
    justify-content: center;
  }
`;

export const GWCount = styled('span')`
  font-size: 26px;
  font-weight: 600;
  color: ${customBlue};
  padding-right: 15px;
  @media (max-width: 425px) {
    font-size: 1.2rem;
    line-height: 1.2;
  }
`;
