import React, { useRef } from 'react';
import { trackClick } from 'utility/analytics';
import { MD } from '@zendeskgarden/react-typography';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { get } from 'lodash';
import { Button } from 'theme/Button';
import { FormInput, Form, FormFooter } from 'theme/Form';
import { Message, Field } from '@zendeskgarden/react-forms';
import { signIn } from 'state/authentication/actions';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';
import { AuthForm, FormTextBottom, FormWrapper } from 'theme/AuthForms.styles';

const { spacing_xl: spacingXl } = variables;

function formValidation(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (get(values.password, 'length') < 6) {
    errors.password = 'Password must be at least 6 characters.';
  }
  return errors;
}

function LoginFormWrapper({ error: propsError, onSuccess }) {
  const formRef = useRef();

  async function submitLogin(values, { setSubmitting, setStatus }) {
    trackClick('submitLogin', 'Login', 'Login');
    setSubmitting(true);
    const signInSuccess = await signIn(values);
    const error = signInSuccess.error || propsError;
    if (error) {
      setStatus({ error });
    } else if (onSuccess) {
      onSuccess();
    }
    setSubmitting(false);
  }

  /* Set the error state from parent
  This is useful because the global state conditions for the Login component error
   may not be updated before submitLogin checks for errors */
  if (propsError) {
    formRef.current.setStatus({ error: propsError });
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={formValidation}
      onSubmit={(...args) => submitLogin(...args)}
      ref={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
      }) => (
        <Form size="large" onSubmit={handleSubmit}>
          <AuthForm className="auth-form-wrapper">
            <Row>
              <Col md={12}>
                <FormWrapper spacing="0px" className="email">
                  <MD bold left tag="h5">
                    Email
                  </MD>
                  <FormInput
                    // Note: removed this so that Link components do not validate so you dont have to double click them to navigate
                    // autoFocus
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="current-email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    showMessage
                    error={errors.email && touched.email && errors.email}
                    icon="icon-profile"
                  />
                </FormWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormWrapper className="password">
                  <MD bold left tag="h5">
                    Password
                  </MD>
                  <FormInput
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    showMessage
                    error={
                      errors.password && touched.password && errors.password
                    }
                    icon="icon-lock"
                  />
                </FormWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {status && status.error ? (
                  <FormFooter>
                    <Field>
                      <Message validation="error">{status.error}</Message>
                    </Field>
                  </FormFooter>
                ) : null}
                <Flex justifyCenter className="button-flex-login">
                  <FlexItem>
                    <Button
                      loading={isSubmitting || undefined}
                      type="submit"
                      primary
                      style={{
                        marginTop: spacingXl,
                        marginBottom: spacingXl,
                      }}
                    >
                      Login
                    </Button>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <FormTextBottom>
              <Row>
                <Col md={12}>
                  <MD center tag="p">
                    <Link to="/recover-password"> Can&apos;t Log In? </Link>
                  </MD>
                </Col>
              </Row>
            </FormTextBottom>
          </AuthForm>
        </Form>
      )}
    </Formik>
  );
}

LoginFormWrapper.propTypes = {
  error: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default LoginFormWrapper;
