import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const { custom_navy, custom_slate, media_md } = variables;

export const StyledSearchWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  .item-selection-filters {
    padding: 0;
  }
  @media ${media_md} {
    margin-bottom: 0px;
    padding-bottom: 20px;
    .item-selection-filters {
      padding: 20px 20px 0;
    }
  }
`;

export const StyledSearchSearch = styled.div`
  background-color: #fff;
  margin: 0 auto;
  padding: 0 10px;
  &&& .filter-wrapper.not_small {
    max-width: 400px;
    @media (max-width: 1050px) {
      min-width: 130px !important;
    }
    @media (max-width: 780px) {
      min-width: 33% !important;
    }
    @media (max-width: 580px) {
      min-width: 50% !important;
    }
  }

  &&& .filter-title {
    font-size: 17px;
    color: ${custom_navy};
    @media (max-width: 780px) {
      font-size: 15px;
    }
  }

  &&& .filter-item-input.operator {
    font-size: 13px;
  }

  &&& .filter-item-input {
    font-size: 14px;
    color: ${custom_navy};
    *,
    & ~ div {
      font-size: 14px;
    }
    &:before {
      opacity: 0.5;
    }
    &.operator {
      color: ${custom_slate};
    }
  }

  .filter-item-group-header {
    margin-bottom: 25px !important;
  }
`;
