import styled from 'styled-components';
import PublicPage from 'components/PublicPages/PublicPage';
import { Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const { media_lg: mediaLg } = variables;

export const ProductsPageWrapper = styled(PublicPage)`
  padding-top: 0px !important;
`;

export const ProductsPageFiltersWrapper = styled(Col)`
  padding: 0 5px;
  max-width: 250px;
  overflow: auto;
  margin-bottom: 10px;
  @media (max-width: 705px) {
    display: none;
  }
`;

export const Filters = styled('div')`
  max-width: 255px;
  max-height: 255px;
  @media ${mediaLg} {
    display: block;
  }
`;

export const ProductPageContentWrapper = styled(Col)`
  padding-left: 20px;
`;
