import React from 'react';
import numeral from 'numeral';
import PublicProductsTableWrapper from 'components/PublicProductsTable/PublicProductsTableWrapper';
import { Row, Col } from '@zendeskgarden/react-grid';
import { LG } from '@zendeskgarden/react-typography';
import { webRefactorSwitch } from 'utility/environment';
import StyledLandingProducts from './LandingProducts.styles.js';

function LandingProducts() {
  return (
    <StyledLandingProducts>
      <Row>
        <Col>
          <div>
            <PublicProductsTableWrapper
              path={['search']}
              showFiltersByDefault
              renderTitle={(total = 0, pagination = {}) => (
                <div>
                  <LG as="span" scaled navy bold>
                    Product Guide{' '}
                  </LG>{' '}
                  {!webRefactorSwitch && (
                    <LG as="span" scaled navy>
                      {' '}
                      ({pagination.limit || 0} of {numeral(total).format('0,0')}
                      )
                    </LG>
                  )}
                </div>
              )}
            />
          </div>
        </Col>
      </Row>
    </StyledLandingProducts>
  );
}

export default LandingProducts;
