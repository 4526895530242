import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import PublicPage from 'components/PublicPages/PublicPage';
const { spacing, custom_border_grey, media_lg, media_md } = variables;

export const SearchWrapper = styled(PublicPage)`
  @media ${media_md} {
    padding: 0 ${spacing};
  }
`;

export const SearchComponentWrapper = styled('div')`
  margin-top: 0px;
  padding: 20px 12px 0;
`;

export const Filters = styled('div')`
  display: none;
  max-width: 255px;
  min-width: 255px;
  padding: 0px 40px 0 0;
  @media ${media_lg} {
    display: block;
  }
`;

export const Content = styled('div')`
  max-width: 100%;
`;

export const SearchSection = styled('div')`
  margin-bottom: 40px;
  @media (min-width: 1100px) {
    .cell_name {
      width: 32% !important;
    }
    .cell_api_internal_pressure {
      width: 11% !important;
      padding-right: 3px !important;
    }
    .cell_collapse_pressure {
      width: 10% !important;
    }
    .cell_metallurgy_group {
      width: 17% !important;
    }
    .cell_wall {
      width: 10% !important;
    }
    .cell_drift {
      width: 8% !important;
    }
    .cell_inside_diameter {
      width: 10% !important;
    }
  }
`;

export const SearchSectionInner = styled('div')`
  .filter-wrapper.od {
    padding-left: 0 !important;
  }
  .filter-wrapper.brand {
    padding-right: 0 !important;
  }
`;

export const SearchDetailsWrapperInner = styled('div')`
  padding: 30px 25px 0;
  display: flex;
  margin-bottom: 40px;
  min-height: 600px;
  & > div {
    flex: 1;
  }
  .accordian-header {
    color: #76899e;
  }
`;

export const SearchDetailsSpinnerWrapper = styled('div')`
  min-height: 600px;
`;

export const CategoryItem = styled('div')`
  width: 100%;
  a {
    display: block;
    padding: ${spacing};
    background: #fff;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid ${custom_border_grey};
    ${(props) =>
      props.active
        ? `
      &:hover, &:focus  {
        
      }
    `
        : ''};
  }
`;
