import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function getLandingCount() {
  const response = await fetchJson(`${BASE}/api/p/landing/count`, {
    method: 'GET',
  });
  return response;
}

export default getLandingCount;
