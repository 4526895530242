import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const { custom_dark_blue } = variables;

export const ProductsCustomInqure = styled('div')`
  .item-selection-accordian-header {
    background: ${custom_dark_blue}; !important
  }

  .item-selection-accordian-header-save {
    background: ${custom_dark_blue} !important;
  }
`;
