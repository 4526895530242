import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { trackClick } from 'utility/analytics';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import {
  isEmpty,
  set,
  startCase,
  keys,
  map,
  debounce,
  reduce,
  unset,
  get,
  replace,
  size,
  cloneDeep,
} from 'lodash';
import Accordion from 'theme/Accordion';
import Flex from 'styled-flex-component';
import { SM } from '@zendeskgarden/react-typography';
import { CheckboxList } from 'components/Checkbox/Checkbox';
// import {setFilters} from 'state/application/actions';
import { reverseCategorizeFacets } from 'utility/reverseUrlFilters';
import categorizeFacets from 'utility/categorizeFacets';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';

const { custom_border_grey: customBorderGrey } = variables;

const DEBOUNCE_TIME = 100;

const facetsDefault = {};
const filtersDefault = {};

function SearchFiltersV2(props) {
  const {
    onFilter,
    facets = facetsDefault,
    sort,
    type,
    filters = filtersDefault,
    keyword,
    style,
  } = props;
  const debounceOnChangeRef = useRef(
    debounce((v, s, cv, options) => onFilter(v, s, cv, options), DEBOUNCE_TIME)
  );

  const selectedValues = useMemo(
    () => (filters ? reverseCategorizeFacets(filters, type) : {}),
    [filters, type]
  );
  const facetGroups = categorizeFacets(facets, type);

  function resetFilterValues() {
    trackClick('resetFilterValues', 'reset', 'FilterSidebar');
    debounceOnChangeRef.current({ keyword }, null, null, { reset: true });
  }

  const onChangeCheckboxList = useCallback(
    (item, checked) => {
      const clonedValues = cloneDeep(selectedValues);
      const itemKey = item.key;
      let itemType = item.type;
      if (item.value === 'missing') {
        itemType = `${itemType}_missing`;
      }

      if (item.isBoolean && checked) {
        set(clonedValues, `${itemType}.${itemKey}`, checked);
        unset(clonedValues, `${itemType}.${!itemKey}`);
      } else if (checked) {
        set(clonedValues, `${itemType}.${'__'}${itemKey}`, checked);
      } else {
        unset(clonedValues, `${itemType}.${'__'}${itemKey}`);
      }
      const urlReadyObject = reduce(
        clonedValues,
        (accum, clonedType, key) => {
          const currentAccum = accum;
          const filterValues = keys(clonedType);
          const isBool =
            get(filterValues, '[0]') === 'false' ||
            get(filterValues, '[0]') === 'true';
          if (filterValues.length) {
            if (get(filterValues, '[0]') === 'missing') {
              currentAccum[key] = true;
            } else if (isBool) {
              const firstVal = filterValues[0];
              currentAccum[key] = firstVal;
            } else {
              currentAccum[key] = map(filterValues, (d) =>
                replace(d, '__', '')
              );
            }
          }
          return currentAccum;
        },
        {}
      );

      let setUrl = urlReadyObject;
      setTimeout(() => {
        if (isEmpty(setUrl)) {
          setUrl = { [itemType]: [] };
        }
        debounceOnChangeRef.current(setUrl, sort, { item, checked });
      }, 1);
    },
    [selectedValues, sort]
  );

  return (
    <div style={style}>
      <Flex justifyEnd style={{ padding: '0 10px', marginBottom: '15px' }}>
        <SM>
          <ZendeskButton blue link onClick={() => resetFilterValues()}>
            Reset Filters
          </ZendeskButton>
        </SM>
      </Flex>

      <div>
        {map(facetGroups, (facet, i) => {
          const selectedFacetValues = {
            ...selectedValues[facet.key],
            ...selectedValues[`${facet.key}_missing`],
          };
          function determineTitle(facetName) {
            if (facetName === 'Metallurgy') {
              return 'Alloy';
            }
            if (facetName === 'Metallurgy Group') {
              return 'Alloy Group';
            }
            return startCase(facetName);
          }
          return facet.buckets && facet.buckets.length ? (
            <SearchFilterGroup
              disabled={!facet.buckets.length}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              title={determineTitle(facet.name)}
              selected={selectedFacetValues}
              onChange={onChangeCheckboxList}
              items={facet.buckets}
              key={`${i}-${facet.key}`}
            />
          ) : null;
        })}
      </div>
    </div>
  );
}

SearchFiltersV2.propTypes = {
  onFilter: PropTypes.func,
  facets: PropTypes.shape({}),
  sort: PropTypes.func,
  type: PropTypes.string,
  filters: PropTypes.shape({}),
  keyword: PropTypes.string,
  style: PropTypes.shape({}),
};

function SearchFilterGroup({
  disabled,
  style,
  title,
  selected,
  onChange,
  items,
}) {
  const hasSelected = keys(selected).length;
  const [clamp, setClamp] = useState(!hasSelected);
  useEffect(() => {
    if (hasSelected) {
      setClamp(false);
    }
  }, [hasSelected]);

  const renderViewMoreOrLess = useCallback(() => {
    if (size(items) > 8 && clamp) {
      return (
        <SM
          grey
          onClick={() => setClamp(false)}
          style={{ marginTop: '10px', fontSize: '14px', padding: '0 15px' }}
          noMargin
          as="div"
          link
        >
          View More
        </SM>
      );
    }
    if (size(items) > 8 && !clamp) {
      return (
        <SM
          grey
          onClick={() => setClamp(true)}
          style={{ marginTop: '0px', fontSize: '14px', padding: '0 15px' }}
          noMargin
          as="div"
          link
        >
          View Less
        </SM>
      );
    }
    return null;
  }, [clamp, items]);

  return (
    <div
      style={{
        background: '#fff',
        padding: '0 0px 15px',
        marginBottom: '10px',
        border: `1px solid ${customBorderGrey}`,
        borderRadius: '6px',
      }}
    >
      <div style={{ maxHeight: clamp ? '230px' : 'none', overflow: 'hidden' }}>
        <Accordion
          disabled={disabled}
          bodyStyle={{ paddingTop: '10px', paddingBottom: 0 }}
          style={style}
          title={title}
        >
          <div style={{ display: 'block', padding: '0 15px' }}>
            <CheckboxList
              selected={selected}
              onChange={onChange}
              items={items}
            />
          </div>
        </Accordion>
      </div>
      {renderViewMoreOrLess()}
    </div>
  );
}

SearchFilterGroup.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  selected: PropTypes.shape({}),
  onChange: PropTypes.func,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
};

export default SearchFiltersV2;
