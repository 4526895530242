import styled from 'styled-components';
import { variables } from 'theme/variables';

const { custom_slate: customSlate, color_blue_700: colorBlue700 } = variables;

export const ProductGuideHeroCard = styled('div')`
  height: 250px;
  max-width: 98%;
  margin: 20px 0px 2px;
  margin-bottom: 20px;
  border-radius: 1em;
  box-shadow: 0 4px 16px #c0cff7;
  background-image: url(/images/oilRig.svg);
  background-repeat: no-repeat;
  background-position: 70% 20%;
  background-size: 30% 100%;
  #heroCardGrid {
    display: flex;
    height: 230px;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  #productGuideHeaderDetails {
    width: 70%;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
  }
  #actionButtons {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    max-width: 30%;
  }
  .expertButtonRow {
    margin-bottom: 10px;
    margin-right: 40px;
  }
  .expertButton {
    max-height: 50px;
    min-width: 100px;
    width: 300px;
  }
  .submitSupplyButtonRow {
    margin-bottom: 10px;
  }
  .submitSupplyButton {
    max-height: 50px;
    min-width: 100px;
    width: 300px;
    background-color: ${colorBlue700} !important;
  }
  @media (max-width: 2478px) {
    .submitSupplyButtonRow {
      margin-right: 40px;
    }
  }
  @media (max-width: 1240px) {
    .expertButton {
      width: 200px;
    }
    .submitSupplyButton {
      width: 200px;
    }
  }
  @media (max-width: 1199px) {
    #heroCardGrid {
      flex-wrap: wrap;
      padding: 20px 0 30px;
    }
    #actionButtons {
      max-width: 80%;
    }
    #productGuideHeaderDetails {
      width: 80%;
      height: 120px;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
    .submitSupplyButtonRow {
      margin-right: 0;
    }
  }
  @media (max-width: 980px) {
    #heroCardGrid {
      flex-wrap: wrap;
    }
    #actionButtons {
      flex-wrap: noWrap;
    }
    .expertButtonRow {
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
    background-image: none;
  }
  @media (max-width: 540px) {
    .expertButton {
      width: 175px;
    }
    .expertButtonRow {
      margin-right: 20px;
    }
    .submitSupplyButton {
      width: 175px;
    }
  }
  @media (max-width: 475px) {
    #productGuideHeaderDetails {
      width: 100%;
      margin: 0 5px;
    }
    .expertButton {
      width: 150px;
    }
    .submitSupplyButton {
      width: 150px;
    }
  }
  @media (max-width: 432px) {
    height: 270px;
    #actionButtons {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
    }
    .expertButton,
    .submitSupplyButton {
      margin-bottom: 5px;
      width: 200px;
    }
  }
  @media (max-width: 375px) {
    height: 300px;
    #actionButtons {
      margin-top: 45px;
    }
  }
`;

export const ProductGuideHeader = styled('h1')`
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
  letter-spacing: 1px;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
    justify-content: center;
    width: 95%;
  }
  @media (max-width: 370px) {
    font-size: 24px;
  }
`;

export const ProductGuideSubHeader = styled('div')`
  font-size: 14px;
  display: flex;
  line-height: 22px !important;
  flex-wrap: wrap;
  color: ${customSlate};
  @media (max-width: 1199px) {
    width: 95%;
  }
`;
