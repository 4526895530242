import React, { useCallback } from 'react';
import { trackClick, track } from 'utility/analytics';
import history from 'historyContainer';
import { Link } from 'react-router-dom';
import { MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import { Formik } from 'formik';
import { get, cloneDeep } from 'lodash';
import { FormInput, Form, FormFooter } from 'theme/Form';
import { Field, Message } from '@zendeskgarden/react-forms';
import { createAccount } from 'state/authentication/actions';
import { PropTypes } from 'prop-types';
import {
  Section,
  Logo,
  AuthForm,
  FormFieldWrapper,
  BackgroundImg,
  BackgroundImgWrapper,
} from 'theme/AuthForms.styles';

const {
  spacing_xl: spacingXl,
  logo_url: logoUrl,
  custom_blue: customBlue,
} = variables;

function CreateAccount(props) {
  return (
    <Section dark>
      <Flex justifyCenter>
        <FlexItem>
          <Logo
            onClick={() => {
              trackClick('logoClick', 'logo', 'Register');
              history.push('/');
            }}
            src={logoUrl}
          />
        </FlexItem>
      </Flex>
      <Flex justifyCenter>
        <AuthFormWrapper
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Flex>
      <BackgroundImgWrapper>
        <BackgroundImg src="/images/hero-pipes.png" />
      </BackgroundImgWrapper>
    </Section>
  );
}

function formValidation(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (get(values.password, 'length') < 6) {
    errors.password = 'Password must be at least 6 characters.';
  }
  return errors;
}

export function AuthFormWrapper({
  onSuccess,
  ignoreMobile,
  cta,
  onClickLogin,
}) {
  const memoSubmitCreateAccount = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      trackClick('submitCreateAccount', 'Submit', 'Register');
      setSubmitting(true);
      const cloneValues = cloneDeep(values);
      const signInSuccess = await createAccount(cloneValues);
      const { error } = signInSuccess;
      if (error) {
        setStatus({ error });
      } else if (onSuccess) {
        track('submitCreateAccountSuccess');
        onSuccess();
      }
      setSubmitting(false);
    },
    [onSuccess]
  );

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: '',
        password: '',
      }}
      validate={formValidation}
      onSubmit={memoSubmitCreateAccount}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        setFieldValue,
      }) => (
        <Form size="large" onSubmit={handleSubmit}>
          <AuthForm className="auth-form-wrapper" style={{ paddingTop: 0 }}>
            <Row>
              <Col extraPaddingRight sm={12} md={6}>
                <FormFieldWrapper>
                  <MD bold left tag="h5">
                    First Name
                  </MD>
                  <FormInput
                    type="text"
                    id="first_name"
                    name="first_name"
                    required
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    showMessage
                    error={
                      errors.first_name &&
                      touched.first_name &&
                      errors.first_name
                    }
                    placeholder="Enter First Name"
                  />
                </FormFieldWrapper>
              </Col>
              <Col extraPaddingLeft sm={12} md={6}>
                <FormFieldWrapper>
                  <MD bold left tag="h5">
                    Last Name
                  </MD>
                  <FormInput
                    type="text"
                    id="last_name"
                    name="last_name"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    showMessage
                    error={
                      errors.last_name && touched.last_name && errors.last_name
                    }
                    placeholder="Enter Last Name"
                  />
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormFieldWrapper>
                  <MD bold left tag="h5">
                    Work Email
                  </MD>
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    showMessage
                    error={errors.email && touched.email && errors.email}
                    placeholder="Enter Email"
                  />
                </FormFieldWrapper>
              </Col>
            </Row>
            {!ignoreMobile ? (
              <Row>
                <Col md={12}>
                  <FormFieldWrapper>
                    <MD bold left tag="h5">
                      Mobile Phone Number
                    </MD>
                    <FormInput
                      phone
                      type="tel"
                      id="mobile_phone"
                      name="mobile_phone"
                      required
                      onChange={(e) => {
                        setFieldValue('mobile_phone', e.currentTarget.value);
                      }}
                      onBlur={handleBlur}
                      value={values.mobile_phone}
                      showMessage
                      error={
                        errors.mobile_phone &&
                        touched.mobile_phone &&
                        errors.mobile_phone
                      }
                      placeholder="Enter Phone Number"
                    />
                  </FormFieldWrapper>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md={12}>
                <FormFieldWrapper>
                  <MD bold left tag="h5">
                    Password
                  </MD>
                  <FormInput
                    type="password"
                    id="password"
                    name="password"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    showMessage
                    error={
                      errors.password && touched.password && errors.password
                    }
                    placeholder="Enter Password"
                  />
                </FormFieldWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {status && status.error ? (
                  <FormFooter>
                    <Field>
                      <Message validation="error">{status.error}</Message>
                    </Field>
                  </FormFooter>
                ) : null}
                <Flex justifyCenter className="button-flex-create-account">
                  <FlexItem>
                    <Button
                      loading={isSubmitting || undefined}
                      type="submit"
                      primary
                      style={{
                        marginTop: spacingXl,
                        marginBottom: spacingXl,
                      }}
                    >
                      {cta || 'Create Account'}
                    </Button>
                  </FlexItem>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p style={{ textAlign: 'center' }} className="already">
                  Already have a PipeSearch account?
                  {onClickLogin ? (
                    <span
                      style={{
                        color: customBlue,
                        cursor: 'pointer',
                        paddingLeft: '7px',
                      }}
                      onClick={onClickLogin}
                      onKeyUp={onClickLogin}
                      role="button"
                      tabIndex={0}
                    >
                      Login
                    </span>
                  ) : (
                    <Link style={{ paddingLeft: '4px' }} to="/login">
                      Login
                    </Link>
                  )}{' '}
                </p>
              </Col>
            </Row>
          </AuthForm>
        </Form>
      )}
    </Formik>
  );
}

AuthFormWrapper.propTypes = {
  onSuccess: PropTypes.func,
  ignoreMobile: PropTypes.bool,
  cta: PropTypes.string,
  onClickLogin: PropTypes.func,
};

export default CreateAccount;
